<template>
  <el-main class="terms-container">
    <MainContain />
    <TermsOfService 
      class="inner-container"
    />
  </el-main>
</template>

<script>
import TermsOfService from '@/components/TermsOfService'
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'Terms',
  components: {
    TermsOfService,
    MainContain
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.terms-container{
  color: $text_dark;
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
}

</style>

